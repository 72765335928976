import React from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { lineColors } from 'AppModules/Ganymede/utils/constants';
import { Intent, Toaster } from '@blueprintjs/core';
import { add, format } from 'date-fns';
import ganymedeAPI from '../API/ganymedeAPI';
import Prices from './Prices';
import './Prices.css';

const queryClient = new QueryClient();
const copyDataToClipboardToaster = Toaster.create();

const PricesContainer = (props) => {
  const token = useSelector((store) => store.authReducer.token || '');
  const filtersSelection = useSelector((store) => store.ganymedeReducer.prices.filtersSelection);
  const pricesQuery = useQuery({ queryKey: JSON.stringify(filtersSelection), queryFn: () => ganymedeAPI.getPricesData(token, filtersSelection) });

  if (pricesQuery.isError) {
    return <span>Error: {pricesQuery.error?.message}</span>;
  }

  const copyDataToClipboard = async (tableHeaders, tableData) => {
    const text = [tableHeaders, ...tableData].map((row) => row.join('\t')).join('\n');
    try {
      await navigator.clipboard.writeText(text);
      copyDataToClipboardToaster.show({ message: 'Data succesfully copied to clipboard', intent: Intent.SUCCESS, icon: 'tick' });
    } catch (e) {
      copyDataToClipboardToaster.show({ message: `Error copying data to clipboard: ${e.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
    }
    // return res;
  };

  const pricesData = pricesQuery.isSuccess
    ? pricesQuery.data.items.map((output, index) => {
        const allDates = output.data.map((el) => new Date(el.eventDate)).sort((a, b) => a - b);
        const lastDate = allDates.slice(-1)[0];
        const filledDates = allDates.slice(0, 1);
        let currentDate = allDates[0];
        while (currentDate < lastDate) {
          currentDate = add(currentDate, { days: 1 });
          filledDates.push(currentDate);
        }
        const filledDates_formatted = filledDates.map((el) => format(el, 'yyyy-MM-dd'));

        const filledPrices = filledDates_formatted.map((date) => {
          const value = output.data.find((el) => format(new Date(el.eventDate), 'yyyy-MM-dd') === date);
          return value?.price;
        });

        return {
          x: filledDates,
          y: filledPrices,
          name: `${output.park} - ${output.product} - ${format(new Date(output.snapshotDate), 'MM/dd/yyyy')}`,
          color: lineColors[index],
        };
      })
    : [];

  // console.log(pricesData);

  return (
    <QueryClientProvider client={queryClient}>
      {pricesQuery.isError ? 'Error' : <Prices pricesData={pricesData} isUpdating={pricesQuery.isLoading} copyDataToClipboard={copyDataToClipboard} />}
    </QueryClientProvider>
  );
};

export default PricesContainer;
