import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Intent, Toaster } from '@blueprintjs/core';
import PricesChart from './PricesChart';

const copyDataToClipboardToaster = Toaster.create();

function transpose(matrix) {
  return matrix[0].map((col, i) => matrix.map((row) => row[i]));
}

const PricesChartContainer = (props) => {
  const { isDarkMode } = useSelector((store) => store.userReducer.userSettings);

  const copyDataToClipboard = async () => {
    const dataTable = [['Event Date', ...props.data[0].x.map((el) => format(new Date(el), 'MM-dd-yyyy'))]];
    for (const series of props.data) {
      dataTable.push([series.name, ...series.y]);
    }
    // console.table(transpose(dataTable));
    const text = transpose(dataTable)
      .map((row) => row.join('\t'))
      .join('\n');
    await navigator.clipboard.writeText(text);
    copyDataToClipboardToaster.show({
      message: 'Data succesfully copied to clipboard',
      intent: Intent.SUCCESS,
      icon: 'tick',
    });
  };

  // console.log(Math.min(...props.data.map((el2) => el2.x).flat().filter((el3) => !!el3)));
  // console.log(add(Math.min(...props.data.map((el2) => el2.x).flat().filter((el3) => !!el3)), { months: 6 }));

  return (
    props.data.length > 0 && (
      <PricesChart
        title={props.title}
        data={props.data}
        width={props.width}
        height={props.height}
        copyDataToClipboard={copyDataToClipboard}
        isDarkMode={isDarkMode}
      />
    )
  );
};

PricesChartContainer.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
      y: PropTypes.arrayOf(PropTypes.number).isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.shape({
        dark: PropTypes.string.isRequired,
        light: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default PricesChartContainer;
